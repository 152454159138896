import { SvgIcon } from "@mui/material";
import React from "react";

export default function InventoryIcon({ ...props }) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path d="M7.88119 12.9175H16.2392C16.661 12.9175 16.9203 12.4882 16.9203 12.0663V4.63754H13.7673C13.1594 4.63754 12.6001 4.0763 12.6001 3.46838V0.497559H7.88119C7.4594 0.497559 7.08008 0.755557 7.08008 1.17754V12.0663C7.08008 12.4882 7.4594 12.9175 7.88119 12.9175ZM9.06667 6.19753H11.5381C11.8031 6.19753 12.0181 6.41245 12.0181 6.67753C12.0181 6.94261 11.8031 7.15753 11.5381 7.15753H9.06667C8.80159 7.15753 8.58667 6.94261 8.58667 6.67753C8.58667 6.41245 8.80159 6.19753 9.06667 6.19753ZM9.06667 8.35752H14.8727C15.1377 8.35752 15.3527 8.57244 15.3527 8.83752C15.3527 9.1026 15.1377 9.31751 14.8727 9.31751H9.06667C8.80159 9.31751 8.58667 9.1026 8.58667 8.83752C8.58667 8.57244 8.80159 8.35752 9.06667 8.35752ZM9.06667 10.5175H14.8727C15.1377 10.5175 15.3527 10.7324 15.3527 10.9975C15.3527 11.2626 15.1377 11.4775 14.8727 11.4775H9.06667C8.80159 11.4775 8.58667 11.2626 8.58667 10.9975C8.58667 10.7324 8.80159 10.5175 9.06667 10.5175Z" />
      <path d="M4.58901 19.6213C3.51729 19.6213 2.6485 20.4901 2.64844 21.5618C2.64838 22.6335 3.51717 23.5023 4.58889 23.5024C5.6606 23.5024 6.5294 22.6336 6.52946 21.5619C6.52826 20.4907 5.66018 19.6225 4.58901 19.6213Z" />
      <path d="M13.7678 3.67748H16.4304L13.5605 0.960938V3.46832C13.5605 3.54686 13.6892 3.67748 13.7678 3.67748Z" />
      <path d="M23.2492 17.0538L21.0172 16.0375C20.9328 15.9984 20.8618 15.9349 20.8136 15.8553L18.8542 12.7785C18.6283 12.4168 18.284 12.1446 17.8799 12.0083V12.0664C17.8799 13.0176 17.1899 13.8775 16.2388 13.8775H7.88084C7.62614 13.8775 7.38326 13.7785 7.16546 13.6791L5.44677 15.9062C5.38137 15.9882 5.29065 16.0462 5.18877 16.071L1.00637 17.2006C0.437098 17.3561 0 17.8735 0 18.4634V19.9782C0 20.7022 0.667977 21.3775 1.39199 21.3775H1.70201C1.85135 19.7828 3.26512 18.6111 4.8598 18.7605C6.24777 18.8904 7.34684 19.9896 7.4768 21.3775H16.3443C16.4936 19.7828 17.9074 18.6111 19.5021 18.7604C20.89 18.8904 21.9891 19.9895 22.1191 21.3775H22.7279C23.4518 21.3775 23.9999 20.7022 23.9999 19.9782V18.249C24.007 17.7376 23.713 17.2696 23.2492 17.0538Z" />
      <path d="M19.2316 19.6213C18.1599 19.6213 17.2911 20.4901 17.291 21.5618C17.291 22.6335 18.1598 23.5023 19.2315 23.5024C20.3032 23.5024 21.172 22.6336 21.172 21.5619C21.1708 20.4907 20.3028 19.6225 19.2316 19.6213Z" />
    </SvgIcon>
  );
}
