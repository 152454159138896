import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { authSet, getUid } from "../redux/authSlice";
import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { firebaseApp } from "../firebase/app";
import PageLoading from "../components/pageLoading/pageLoading";
import DashboardLayout from "../layout/dashboard-layout";

const PrivateRoute = () => {
  const uid = useSelector(getUid);
  const auth = uid;
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth(firebaseApp);
    onAuthStateChanged(auth, (user: any) => {
      if (user) {
        const { uid, displayName, accessToken } = user;
        const userData = {
          displayName,
          uid,
          token: accessToken,
        };
        dispatch(authSet(userData));
        setLoading(false);
      } else {
        console.log("unauthorize");
        setLoading(false);
        dispatch(authSet(undefined));
        navigate("/login");
      }
    });
  }, [dispatch, navigate]);

  if (loading) return <PageLoading />;
  else
    return auth ? (
      <DashboardLayout>
        <Outlet />
      </DashboardLayout>
    ) : (
      <Navigate to="/login" />
    );
};

export default PrivateRoute;
