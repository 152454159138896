import React, { useState } from "react";
import Dialog from "../../components/dialog/dialog";
import { Box, CircularProgress, Typography } from "@mui/material";
import BasicRating from "../../components/rating";
import { getData } from "../../utils/firebase";
import { push, ref } from "firebase/database";
import { firebaseDb } from "../../firebase/app";
import { getUid } from "../../redux/authSlice";
import { useSelector } from "react-redux";
import { Button } from "../../components";
import CustomSnackbar from "../../components/snackbar/custom-snackbar";
import Textarea from "../../components/textarea/textarea";

// Get the current timestamp in UTC milliseconds
const currentTimestampUTC = Date.now();

export default function Feedback({ open, handleClose }: any) {
  const [value1, setValue1] = React.useState<number | null>(0);
  const [value2, setValue2] = React.useState<number | null>(0);
  const [value3, setValue3] = React.useState<number | null>(0);
  const [detail, setDetail] = React.useState("");
  const [feedback, setFeedback] = useState({});
  const [state, setState] = useState({ message: "", open: false, type: "" });

  const uid = useSelector(getUid);

  React.useEffect(() => {
    const handleGetItem = () => {
      getData("/front/feedback")
        .then((r: any) => {
          if (!r) return;
          setFeedback(r);
        })
        .catch((r) => console.log(r));
    };

    handleGetItem();
  }, []);

  const handleInitial = () => {
    setValue1(0);
    setValue2(0);
    setValue3(0);
    setDetail("");
  };

  const sendUserFeedback = () => {
    const data: any = {};
    data[Object.keys(feedback)[0]] = value1;
    data[Object.keys(feedback)[1]] = value2;
    data[Object.keys(feedback)[2]] = value3;
    data["detail"] = detail;
    data["timestamp"] = currentTimestampUTC;

    push(ref(firebaseDb, `feedback/${uid}`), data)
      .then(() => {
        handleInitial();
        setState({ message: "Your feedback successfully sent.", open: true, type: "" });
      })
      .catch(() => setState({ message: "error", open: true, type: "error" }));
  };

  const renderTitle = "Feedback";

  const renderContent = (
    <Box>
      <Typography variant="body2" fontWeight={700} marginBottom={"20px"}>
        Thank you for taking the time to provide us with your valuable feedback. Please answer
        following questions.
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          flexDirection: "column",
          gap: "10px",
          paddingBottom: "0",
        }}
      >
        {[
          { title: Object.values(feedback)[0], value: value1, setValue: setValue1 },
          { title: Object.values(feedback)[1], value: value2, setValue: setValue2 },
          { title: Object.values(feedback)[2], value: value3, setValue: setValue3 },
        ].map((d: any, id) => (
          <Box
            key={id}
            display={"flex"}
            width={"100%"}
            justifyContent={"space-between"}
            bgcolor={(theme) => theme.palette.background.default}
            p={1}
            borderRadius={1}
          >
            <Typography>{d?.title}</Typography>
            <BasicRating value={d.value} setValue={d.setValue} />
          </Box>
        ))}
        <Textarea
          id="content"
          placeholder="Enter your criticism or suggestion.. (optional)"
          onChange={(e: { target: { value: any } }) => setDetail(e.target.value)}
          value={detail}
          name="content"
          label="Detail"
          rows={8}
        />
      </Box>
      {state.open && (
        <CustomSnackbar
          handleClose={() => {
            setState({ message: "", open: false, type: "" });
            handleClose();
          }}
          message={state.message}
          open={state.open}
          type={state.type}
        />
      )}
    </Box>
  );
  const renderActions = (
    <Box display={"flex"} justifyContent={"center"} width={"100%"} gap={1}>
      <Button color="primary" variant="contained" onClick={sendUserFeedback}>
        Submit
      </Button>
    </Box>
  );

  return (
    <Dialog
      open={open}
      fullWidth
      handleClose={handleClose}
      renderActions={renderActions}
      renderContent={
        feedback && Object.values(feedback).length === 0 ? <CircularProgress /> : renderContent
      }
      renderTitle={renderTitle}
    />
  );
}
