import { FormControl, Input as MuiInput, Typography } from "@mui/material";

import React, { memo } from "react";
import InputError from "../input-error";

function Textarea({ error, fullWidth = true, label, sx, ...rest }: any) {
  return (
    <FormControl fullWidth={fullWidth} sx={{ mb: 1 }}>
      {label && (
        <Typography fontWeight={500} mb={1} variant="body1">
          {label}
        </Typography>
      )}
      <MuiInput
        multiline
        variant="outlined"
        sx={{
          background: (theme) => theme.palette.background.paper,
          borderRadius: 2,
          fontSize: "small",
          padding: (theme) => theme.spacing(1, 2),
          border: (theme: any) =>
            error ? "1px solid #E84933" : `1px solid ${theme.palette.secondary[200]}`,
          ...sx, // Merge the sx prop here
        }}
        disableUnderline
        {...rest}
      />
      {error && <InputError message={error} />}
    </FormControl>
  );
}

export default memo(Textarea);
