import React from "react";
import { Grid, Typography } from "@mui/material";
import { ReactNode } from "react";

export default function VoiceImageSection({
  title,
  image,
  children,
  align,
}: {
  title: string;
  image: string;
  children: ReactNode;
  align: string;
}) {
  return (
    <Grid
      display="flex"
      alignItems="center"
      flexDirection={align === "left" ? "row" : "row-reverse"}
      paddingLeft={align === "left" ? "50px" : "0"}
      paddingRight={align === "right" ? "120px" : "0"}
      gap="10px"
      height="100px"
    >
      <Grid item xs={6}>
        <img src={`/images/${image}.svg`} width={image === "Frame3" ? 200 : 100} height={100} />
      </Grid>
      <Grid item xs={3} width="400px">
        <Typography fontWeight="bold" fontSize="18px">
          {title}
        </Typography>
        {children}
      </Grid>
    </Grid>
  );
}
