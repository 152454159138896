import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import { Stack } from "@mui/material";

import LeftSidebar from "./left-sidebar";
import Navbar from "./navbar";
import { navAndBottomHeightPx, navHeight, navHeightPx } from "../config/constant";
import BottomNavigation from "./navigation";

import { useResponsive } from "../hooks/use-media-query";
import { getActiveConId } from "../redux/userSlice";
import { useSelector } from "react-redux";

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    "& .MuiDrawer-paper": {
      position: "relative",
      whiteSpace: "nowrap",
      width: drawerWidth,
      borderRight: "none",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: "border-box",
      ...(!open && {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
          width: theme.spacing(9),
        },
      }),
    },
  })
);

export default function DashboardLayout({ children }: any) {
  const [open] = React.useState(false);
  const { isCustomLayout, isMobile } = useResponsive();
  const activeConId = useSelector(getActiveConId);

  const handleOpen = () => {
    // setOpen(!open);
  };

  const renderLeftDrawer = (
    <>
      {isCustomLayout ? (
        <MuiDrawer anchor={"left"} open={open} onClose={handleOpen}>
          <List
            component="nav"
            sx={{
              borderRight: "none",
              width: 100,
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <LeftSidebar />
          </List>
        </MuiDrawer>
      ) : (
        <Drawer
          variant="permanent"
          open={open}
          sx={{
            paddingTop: navHeight,
          }}
        >
          <List
            component="nav"
            sx={{
              borderRight: "none",
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <LeftSidebar openSidebar={open} />
          </List>
        </Drawer>
      )}
    </>
  );

  return (
    <Box sx={{ display: "flex" }}>
      {isCustomLayout ? <Navbar /> : <Navbar />}
      {renderLeftDrawer}
      <Box
        component="main"
        height={
          isCustomLayout
            ? isMobile && activeConId
              ? "calc(100dvh - 48px)"
              : `calc(100dvh  - ${navAndBottomHeightPx})`
            : `calc(100dvh  - ${navHeightPx})`
        }
        maxHeight="-webkit-fill-available"
        margin={!isCustomLayout ? `64px 0 0 0` : isMobile && activeConId ? "0" : `64px 0 0 0`}
        sx={{
          flexGrow: 1,
          // margin: isCustomLayout ? `${navHeightPx} 0 0 0` : `${navHeightPx} 0 ${bottomHeight} 0`,
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        <Stack flex={1} m={"auto"} width={"98%"} height={"100%"}>
          <Stack
            sx={{
              m: "auto",
              width: "100%",
              height: isMobile ? "97%" : "95%",
            }}
          >
            {children}
          </Stack>
        </Stack>
      </Box>
      {isCustomLayout && <BottomNavigation />}
    </Box>
  );
}
