import * as React from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 30, // Decreased width
  height: 16, // Decreased height
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 12, // Decreased thumb size on active
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(10px)", // Adjust translation for smaller size
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2, // Adjust padding for smaller switch
    "&.Mui-checked": {
      transform: "translateX(12px)", // Adjust for smaller size
      color: "#07A4FC",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#A4DEFE",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 1px 2px 0 rgb(0 35 11 / 20%)",
    width: 12, // Decreased thumb size
    height: 12, // Decreased thumb size
    borderRadius: 6, // Adjust border radius for smaller thumb
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2, // Adjust for smaller track
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

export default function CustomizedSwitches({ ...props }) {
  return (
    <FormGroup>
      <Stack direction="row" spacing={1} alignItems="center">
        <AntSwitch inputProps={{ "aria-label": "ant design" }} {...props} />
      </Stack>
    </FormGroup>
  );
}
