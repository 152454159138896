import { FormControl, Input as MuiInput, Typography } from "@mui/material";

import React, { memo } from "react";
import InputError from "../input-error";

function ControlledInput({
  error,
  fullWidth = true,
  label,
  sx,
  // labelColor,
  labelSize,
  iconImage,
  disabled,
  ...rest
}: any) {
  return (
    <FormControl fullWidth={fullWidth}>
      {label && (
        <Typography
          // color={labelColor ? labelColor : "#2F2F2F"}
          fontWeight={500}
          fontSize={labelSize ? labelSize : "14px"}
          mb={1}
          variant="body1"
        >
          {label}
        </Typography>
      )}
      <MuiInput
        variant="outlined"
        sx={{
          background: (theme) => (disabled ? "#EEE" : theme.palette.background.paper),
          borderRadius: "2rem",
          fontSize: "small",
          padding: (theme) => theme.spacing(1, 2),
          border: (theme: any) =>
            error ? "1px solid #E84933" : `1px solid ${theme.palette.secondary[200]}`,
          "&:focus": { border: "1px solid red !important" },
          ...sx, // Merge the sx prop here
        }}
        disableUnderline
        startAdornment={
          iconImage ? (
            <img src={iconImage} width={20} height={20} style={{ marginRight: "10px" }} />
          ) : (
            ""
          )
        }
        disabled={disabled ? disabled : false}
        {...rest}
      />
      {error && <InputError message={error} />}
    </FormControl>
  );
}

export default memo(ControlledInput);
