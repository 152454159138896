import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState: {
  AIMode: boolean;
  activeConId: undefined;
  sortValue: string;
  dealerName: string;
  openCreateLead: boolean;
  files: any;
  hasNewQuestion: boolean;
  isActiveExtension: boolean;
} = {
  AIMode: true,
  activeConId: undefined,
  sortValue: "All Message",
  dealerName: "",
  openCreateLead: false,
  files: [],
  hasNewQuestion: false,
  isActiveExtension: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    filesSet: (state, { payload }) => {
      if (!payload) return;
      const newItem: any = payload;
      // Check if the item with the same ID already exists
      const exists = state.files.some((item: { id: any }) => item.id === newItem.id);
      if (exists) {
        return state; // If it exists, return the current state without changes
      }
      state.files.push(newItem);
    },
    openCreateLeadToggled: (state) => {
      state.openCreateLead = !state.openCreateLead;
    },
    isActiveExtensionToggled: (state, { payload }) => {
      state.isActiveExtension = payload;
    },
    dealerNameSet: (state, { payload }) => {
      state.dealerName = payload;
    },
    sortValueSet: (state, { payload }) => {
      state.sortValue = payload;
    },
    activeConIdSet: (state, { payload }) => {
      state.activeConId = payload;
    },
    AImodeSet: (state, { payload }) => {
      state.AIMode = payload;
    },
    setHasNewQuestion: (state, { payload }) => {
      state.hasNewQuestion = payload;
    },
  },
});

export const getFiles = createSelector(
  (state: any) => state.user,
  (user: any) => user.files
);

export const getOpenCreateLead = createSelector(
  (state: any) => state.user,
  (user: any) => user.openCreateLead
);

export const getIsActiveExtensionToggled = createSelector(
  (state: any) => state.user,
  (user: any) => user.isActiveExtension
);

export const getIsOpenLead = createSelector(
  (state: any) => state.user,
  (user: any) => user.isOpenLead
);

export const userInfo = createSelector(
  (state: any) => state.user,
  (user: any) => user.userInfo
);

export const getIsOpenChat = createSelector(
  (state: any) => state.user,
  (user: any) => user.chatOpen
);

export const getAIMode = createSelector(
  (state: any) => state.user,
  (user: any) => user.AIMode
);

export const getMenuIndex = createSelector(
  (state: any) => state.user,
  (user: any) => user.settingMenuIndex
);

export const getMenuOpen = createSelector(
  (state: any) => state.user,
  (user: any) => user.settingMenuOpen
);

export const getActiveConId = createSelector(
  (state: any) => state.user,
  (user: any) => user.activeConId
);

export const getSortValue = createSelector(
  (state: any) => state.user,
  (user: any) => user.sortValue
);

export const getHotLength = createSelector(
  (state: any) => state.user,
  (user: any) => user.hotLength
);

export const getAppointmentLength = createSelector(
  (state: any) => state.user,
  (user: any) => user.appointmentLength
);

export const getContactAttemptedLength = createSelector(
  (state: any) => state.user,
  (user: any) => user.contactAttemptedLength
);

export const getColdLength = createSelector(
  (state: any) => state.user,
  (user: any) => user.coldLength
);

export const getDealerName = createSelector(
  (state: any) => state.user,
  (user: any) => user.dealerName
);

export const getAllConLength = createSelector(
  (state: any) => state.user,
  (user: any) => user.allConLength
);

export const getFeedBackDialog = createSelector(
  (state: any) => state.user,
  (user: any) => user.feedBackDialog
);

export const getHasNewQuestion = createSelector(
  (state: any) => state.user,
  (user: any) => user.hasNewQuestion
);

export default userSlice.reducer;

export const {
  AImodeSet,
  activeConIdSet,
  sortValueSet,
  dealerNameSet,
  openCreateLeadToggled,
  filesSet,
  setHasNewQuestion,
  isActiveExtensionToggled,
} = userSlice.actions;
