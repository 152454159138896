import { Alert, Box, Typography, useTheme } from "@mui/material";
import Dialog from "../../components/dialog/dialog";
import React from "react";
import { push, ref } from "firebase/database";
import { firebaseDb } from "../../firebase/app";
import { getUid } from "../../redux/authSlice";
import { useSelector } from "react-redux";
import { Button } from "../../components";
import ControlledInput from "../../components/input/controlled-input";
import Textarea from "../../components/textarea/textarea";
import CustomSnackbar from "../../components/snackbar/custom-snackbar";
import MuiPhone from "../../components/input/mui-phone-input";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import {
  EMAIL_REGEX,
  MOBILE_REGEX,
  validateEmail,
  validateMobileNumber,
} from "../../utils/utils.service";

const schema = yup.object().shape({
  name: yup.string().required("Please enter your name"),
  phoneNumber: yup
    .string()
    .required("Please enter your phone number")
    .matches(MOBILE_REGEX, "please enter a valid phone number"),
  email: yup
    .string()
    .required("Email is required")
    .email("Please enter a valid email")
    .matches(EMAIL_REGEX, "Please enter a valid email"),
  detail: yup.string().required("Please enter your content"),
});

interface FormInputs {
  name: string;
  phoneNumber: string;
  email: string;
  detail: string;
}

export default function ContactUs({ open, handleClose }: any) {
  const uid = useSelector(getUid);
  const theme = useTheme();

  const [message, setMessage] = React.useState({
    text: "",
    type: "",
    open: false,
  });

  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>({
    defaultValues: { phoneNumber: "+1", name: "", email: "", detail: "" },
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const { name, email, phoneNumber, detail }: any = getValues();

  const onSubmit = () => {
    push(ref(firebaseDb, `/contactUs/${uid}`), { name, email, phoneNumber, content: detail })
      .then(() => {
        setMessage({
          text: "success",
          type: "",
          open: true,
        });
      })
      .catch((e) => {
        setMessage({
          text: e.message,
          type: "error",
          open: true,
        });
      });
  };
  const renderTitle = "Contact Us";
  const renderContent = (
    <Box display={"grid"} gap={"25px"} mx={"auto"} my={"1rem"} width={"500px"}>
      <Typography variant="body2">
        Please fill out the form bellow and someone from Drivee A.I. will contact you soon.
      </Typography>
      <Box p={1}>
        <Box display={"flex"} justifyContent="space-between" gap={4}>
          <Controller
            control={control}
            name="name"
            render={({ field }) => (
              <ControlledInput
                error={errors.name?.message}
                id="fullName"
                label="Full Name *"
                placeholder="Enter your name"
                type="text"
                {...field}
              />
            )}
          />

          <Controller
            control={control}
            name="phoneNumber"
            render={({ field }) => (
              <MuiPhone
                label={"Phone Number *"}
                id="phoneNumber"
                placeholder="Enter your phone number"
                error={errors.phoneNumber && errors.phoneNumber.message}
                style={{ backgroundColor: "white" }}
                {...field}
              />
            )}
          />
        </Box>
        <Box display={"flex"} gap={1} my={1}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "80%",
            }}
          >
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <ControlledInput
                  error={errors.email?.message}
                  id="email"
                  label="Email *"
                  placeholder="Enter your email"
                  type="text"
                  {...field}
                />
              )}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Controller
            control={control}
            name="detail"
            render={({ field }) => (
              <Textarea
                id="detail"
                error={errors.detail?.message}
                placeholder="Enter your content"
                label="Detail *"
                rows={8}
                {...field}
              />
            )}
          />
        </Box>
      </Box>
      <Alert sx={{ backgroundColor: "#EAF7FE", color: "#0285CF" }} variant="filled" severity="info">
        For immediate/emergency assistance please call us at{" "}
        <span style={{ fontWeight: "bold" }}>888-288-8856</span>.
      </Alert>
      {message.open && (
        <CustomSnackbar
          handleClose={() => {
            setMessage({ text: "", open: false, type: "" });
            handleClose();
          }}
          message={message.text}
          open={message.open}
          type={message.type}
        />
      )}
    </Box>
  );
  const renderActions = (
    <>
      <Box display={"flex"} justifyContent={"center"} width={"100%"} gap={1}>
        <Button onClick={handleClose} variant="outlined">
          Cancel
        </Button>
        <Button
          disabled={!validateEmail(email) || !validateMobileNumber(phoneNumber) || !detail || !name}
          type="submit"
          variant="contained"
        >
          Submit
        </Button>
      </Box>
    </>
  );
  return (
    <Dialog
      bgColor={theme.palette.background.default}
      open={open}
      onSubmit={handleSubmit(onSubmit)}
      handleClose={handleClose}
      renderTitle={renderTitle}
      renderContent={renderContent}
      renderActions={renderActions}
    />
  );
}
