import { CssBaseline, ThemeProvider } from "@mui/material";
import Layout from "./layout/layout";
import React from "react";
import { darkTheme, lightTheme } from "./config/theme";
import { useSelector } from "react-redux";
import { getMode } from "./redux/layoutSlice";
import { BrowserRouter } from "react-router-dom";

function App() {
  const mode = useSelector(getMode);

  return (
    <BrowserRouter>
      <ThemeProvider theme={mode === "dark" ? darkTheme : lightTheme}>
        <CssBaseline />
        <Layout />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
