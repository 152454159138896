import lottie from "lottie-web";
import React, { memo, useEffect, useRef } from "react";

function Animation({ animationData, autoplay, loop, style, ...rest }: any) {
  const container: any = useRef();
  const animationInstance = useRef<any>(null); // Store the animation instance

  useEffect(() => {
    // Load the animation
    animationInstance.current = lottie.loadAnimation({
      animationData,
      autoplay,
      container: container.current,
      loop,
      renderer: "svg",
    });

    // Cleanup function to stop and destroy the animation
    return () => {
      if (animationInstance.current) {
        animationInstance.current.stop(); // Stop the animation
        animationInstance.current.destroy(); // Destroy the animation instance
      }
    };
  }, [animationData, autoplay, loop, style]);

  return <div ref={container} style={style} {...rest} />;
}

export default memo(Animation);
