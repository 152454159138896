import React, { useCallback, useEffect } from "react";
import { Box, FormControlLabel, Grid, Stack, Typography } from "@mui/material";

import Dialog from "../../components/dialog/dialog";
import { useState } from "react";
import * as yup from "yup";
import Snackbar from "../../components/snackbar/snackbar";
import { push, ref } from "firebase/database";
import { firebaseDb } from "../../firebase/app";
import { getUid } from "../../redux/authSlice";
import { useSelector } from "react-redux";
import MuiPhone from "../../components/input/mui-phone-input";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Checkbox from "@mui/material/Checkbox";
import { getData } from "../../utils/firebase";
import { Button } from "../../components";
import ControlledInput from "../../components/input/controlled-input";
import Textarea from "../../components/textarea/textarea";
import CalendarOutlinedIcon from "../../assets/icon/calendar-outlined";
import CalendarFilledIcon from "../../assets/icon/calendar-filled";
import { formatDateFrom } from "../../utils/time-convert";
import IconButton from "../../components/icon-button/icon-button";
import RemoveIcon from "../../assets/icon/remove";
import { EMAIL_REGEX } from "../../utils/utils.service";

import Scheduler from "../../components/utils/scheduler";
import { useResponsive } from "../../hooks/use-media-query";
import InputSearchIcon from "../../assets/icon/input-search";
import CloseIcon from "../../assets/icon/close";

const searchItems = ["year", "make", "model", "VIN", "mileage", "priceOnWebsite"];

const schema = yup.object().shape({
  firstName: yup
    .string()
    .required("Please enter your first name")
    .max(20, "first name must be at maximum 20 characters long"),
  lastName: yup.string().required("Please enter your last name"),
  phoneNumber: yup.string().required("Please enter your phone number"),
  email: yup
    .string()
    .required("Please enter your email")
    .email("Please enter a valid email")
    .matches(EMAIL_REGEX, "Please enter a valid email"),
  manualLeadFirstMessage: yup.string().notRequired(),
  description: yup.string().required("Description is required").min(3).max(255),
  testDrive: yup.boolean(),
  make: yup.string().required(),
  mileage: yup.number().required(),
  model: yup.string().required(),
  priceOnWebsite: yup.number().required(),
  year: yup.number().required("please enter year"),
  VINNumber: yup
    .string()
    .required("VIN number is required")
    .matches(/^(?=[A-HJ-NPR-Z0-9]{17})[A-HJ-NPR-Z0-9]{17}$/, "Invalid VIN number format"), // Adjust the regex as needed
});

const steps = ["Lead Info", "Car Info", "A.I. Settings"];

interface FormInputs {
  firstName: string;
  lastName: string | any;
  phoneNumber: string;
  email: string | any;
  testDrive?: boolean;
  description: string;
  VINNumber: string;
  make: string;
  mileage: number;
  model: string;
  priceOnWebsite: number;
  year: number;
  manualLeadFirstMessage?: string | any;
}

const defaultValues: FormInputs = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  description: "",
  VINNumber: "",
  testDrive: false,
  make: "",
  mileage: 0,
  model: "",
  priceOnWebsite: 0,
  year: 0,
  manualLeadFirstMessage: "",
};

export default function CreateLead({ open, handleClose }: any) {
  // Selector
  const uid = useSelector(getUid);

  const [activeStep, setActiveStep] = React.useState(0);
  const [calendarOpen, setCalendarOpen] = React.useState(false);
  const [isSkipped, setIsSkipped] = React.useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const [lead, setLead] = useState(defaultValues);
  const [timestamp, setTimestamp] = useState<any>("");
  const [message, setMessage] = React.useState({
    text: "",
    type: "",
    open: false,
  });
  const [selected, setSelected] = useState<any>();
  const [searchOptions, setSearchOptions] = useState<any[]>([]);
  const [filteredSearchOptions, setFilteredSearchOptions] = useState<any[]>([]);
  const [filters, setFilters] = useState({
    year: "",
    make: "",
    model: "",
    VIN: "",
    mileage: "",
    priceOnWebsite: "",
  });

  const { isMobile, isSmall } = useResponsive();

  const handleNext = () => {
    if (calendarOpen) {
      setCalendarOpen(false);
    } else {
      if (activeStep === steps.length - 1) {
        addNewLead();
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const handleBack = () => {
    if (calendarOpen) {
      setCalendarOpen(false);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleOpenMenu = () => {
    setCalendarOpen(true);
    // setAnchorEl(event.currentTarget);
  };

  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm<FormInputs>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const firstName = watch("firstName");
  const lastName = watch("lastName");
  const phoneNumber = watch("phoneNumber");
  const email = watch("email");
  const description = watch("description");

  function handleCloseDialog() {
    handleClose();
    reset();
    setLead(defaultValues);
    setActiveStep(0);
    setCalendarOpen(false);
    setFilters({ year: "", make: "", model: "", VIN: "", priceOnWebsite: "", mileage: "" });
    setSelected(undefined);
  }

  const addNewLead = async () => {
    const nowTimestamp = Date.now();

    const { firstName, lastName, email, phoneNumber, description, manualLeadFirstMessage }: any =
      getValues();

    const {
      mileage = null,
      year,
      priceOnWebsite,
      VIN,
      make = null,
      model = null,
    } = selected
      ? selected
      : { mileage: null, year: null, priceOnWebsite: null, VIN: null, make: null, model: null };

    const data: any = selected
      ? {
          firstName: firstName?.trim(),
          lastName: lastName?.trim(),
          email: email?.trim(),
          phoneNumber,
          description,
          VINNumber: VIN || null,
          testDrive: lead.testDrive,
          make,
          mileage: mileage ? parseInt(mileage) : null,
          model,
          priceOnWebsite: priceOnWebsite ? parseInt(priceOnWebsite) : null,
          year: year ? parseInt(year) : null,
          source: "Manual Entry",
          manualLeadFirstMessage,
          leadStatus: "hot",
        }
      : {
          firstName: firstName?.trim(),
          lastName: lastName?.trim(),
          email: email?.trim(),
          phoneNumber,
          description,
          testDrive: lead.testDrive,
          source: "Manual Entry",
          manualLeadFirstMessage,
          leadStatus: "hot",
        };
    if (!VIN) delete data.VINNumber;
    if (!make) delete data.make;
    if (!mileage) delete data.mileage;
    if (!model) delete data.model;
    if (!priceOnWebsite) delete data.priceOnWebsite;
    if (!year) delete data.year;

    if (!data.email) {
      delete data.email;
    }
    if (!data.phoneNumber) {
      delete data.phoneNumber;
    }

    if (timestamp !== "") {
      data.appointmentTime = timestamp;
      data.appointmentIsSet = true;
      data.appointmentSetTS = nowTimestamp;
    }
    if (manualLeadFirstMessage === "" || manualLeadFirstMessage === undefined)
      delete data.manualLeadFirstMessage;

    try {
      const newLeadRef = ref(firebaseDb, `dealers/${uid}/leads`);
      await push(newLeadRef, data); // Push the data to Firebase

      setMessage({
        text: "Lead added successfully!",
        type: "success",
        open: true,
      });
      setLead(defaultValues);
      setTimestamp("");
      handleCloseDialog();
      setActiveStep(0);
      setSelected(undefined);
    } catch (error) {
      // Check if error is an instance of Error
      const errorMessage = error instanceof Error ? error.message : "An unknown error occurred";
      console.error("Error adding lead:", errorMessage);
      setMessage({
        text: errorMessage,
        type: "error",
        open: true,
      });
    }
  };

  // const handleGetItem = useCallback(
  //   (VINNumber: string) => {
  //     if ("VINNumber" in errors) return;
  //     if (VINNumber)
  //       getData(`/cars/${uid}/${VINNumber}`).then((r: any) => {
  //         if (r?.make) setValue("make", r?.make);
  //         if (r?.mileage) setValue("mileage", r?.mileage);
  //         if (r?.model) setValue("model", r?.model);
  //         if (r?.priceOnWebsite) setValue("priceOnWebsite", r?.priceOnWebsite);
  //         if (r?.year) setValue("year", r?.year);
  //       });
  //   },
  //   [errors, setValue, uid]
  // );

  const handleSkipCarInfo = () => {
    setIsSkipped(true);
    setActiveStep(2);
  };

  const handleGetData = useCallback(() => {
    getData(`/cars/${uid}`)
      .then((r: any) => {
        const entries = Object.entries(r);
        const items: any = entries.map(([key, item]: any, id) => {
          return { ...item, vin: key, id: id + 1 };
        });
        setSearchOptions(items);
      })
      .catch(() => {
        setSearchOptions([]);
        setSelected(null);
      });
  }, [uid]);

  useEffect(() => {
    if (open && activeStep === 1) {
      handleGetData();
    }
  }, [activeStep, handleGetData, open]);

  useEffect(() => {
    if (searchOptions.length > 0 && !selected) {
      const { year, make, model, VIN } = filters;
      if (!year && !make.trimStart() && !model.trimStart() && !VIN.trimStart()) {
        setFilteredSearchOptions([]);
        return;
      }
      const filtered = searchOptions.filter((item: any) => {
        return (
          item?.vin?.toLowerCase().includes(VIN?.trim()?.toLowerCase()) &&
          item?.make?.toLowerCase().includes(make?.trim()?.toLowerCase()) &&
          item?.model?.toLowerCase().includes(model?.trim()?.toLowerCase()) &&
          item?.year?.toString()?.includes(year?.trim())
        );
      });
      setFilteredSearchOptions(filtered);
    }
  }, [filters, searchOptions, selected]);

  useEffect(() => {
    if (selected) {
      const { vin: VIN, year, make, model, priceOnWebsite, mileage } = selected;
      setFilters((filters) => ({ ...filters, VIN, year, make, mileage, priceOnWebsite, model }));
    }
  }, [selected, setValue]);

  // handle the disability of Next button
  useEffect(() => {
    if (activeStep === 0) {
      if (
        firstName &&
        lastName &&
        ((email && !errors.email) || (phoneNumber?.length === 12 && !errors.phoneNumber))
      ) {
        setButtonDisabled(false);
      } else {
        setButtonDisabled(true);
      }
    }
    if (activeStep === 1) {
      if (!selected) {
        setButtonDisabled(true);
      } else {
        setButtonDisabled(false);
      }
    }
    if (activeStep === 2) {
      if (calendarOpen) {
        setButtonDisabled(false);
      } else {
        if (!description) {
          setButtonDisabled(true);
        } else {
          setButtonDisabled(false);
        }
      }
    }
  }, [
    watch,
    activeStep,
    calendarOpen,
    firstName,
    lastName,
    email,
    phoneNumber,
    description,
    errors.email,
    errors.phoneNumber,
    errors.VINNumber,
    selected,
  ]);

  const renderTitle = "Create Lead";

  const renderTestDrive = (
    <Box marginBottom={"20px"}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Stack>
          <Typography>Has he/she already test drove this car? (Yes No)</Typography>
        </Stack>
      </Box>

      <Box display={"flex"} justifyContent={"start"} gap={1}>
        <FormControlLabel
          sx={{ flexDirection: "row", margin: "0" }}
          value={true}
          control={
            <Checkbox sx={{ paddingLeft: "0" }} checked={lead.testDrive != false ? true : false} />
          }
          label="Yes"
          labelPlacement="bottom"
          checked={lead.testDrive}
          onClick={() => {
            setLead((prev) => ({
              ...prev,
              testDrive: true,
            }));
          }}
        />
        <FormControlLabel
          sx={{ flexDirection: "row" }}
          control={<Checkbox checked={lead.testDrive === false ? true : false} />}
          label="No"
          labelPlacement="bottom"
          onClick={() => {
            setLead((prev) => ({
              ...prev,
              testDrive: false,
            }));
          }}
        />
      </Box>
    </Box>
  );

  const renderContent = (
    <Box sx={{ width: "100%" }}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        padding="5px 20px"
        sx={{
          backgroundColor: (theme: any) => theme.palette.secondary[100],
          alignItems: "center",
          height: "100px",
          borderRadius: "8px",
          paddingX: "50px",
          marginBottom: "20px",
          maxWidth: "100%",
          marginX: "15px",
        }}
      >
        {steps.map((label, index) => (
          <Box
            key={label}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {index !== 0 && (
              <Box
                sx={{
                  height: "2px",
                  width: isMobile ? "0" : "180px",
                  backgroundColor: index <= activeStep ? "#07A4FC" : "#A0A0A0",
                  marginX: "5px",
                }}
              ></Box>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "5px",
                // marginBottom: "10px ",
              }}
            >
              <Box
                width="20px"
                height="20px"
                borderRadius="50%"
                textAlign="center"
                color={index > activeStep ? "#07A4FC" : "white"}
                sx={{
                  position: "relative",
                  backgroundColor:
                    index > activeStep ? "white" : index === activeStep ? "#07A4FC" : "#36B27E",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "11px",

                  border: index > activeStep ? "1px solid #07A4FC" : "none",
                }}
              >
                {index + 1}
                <Box
                  sx={{
                    fontWeight: activeStep === index ? "bold" : "normal",
                    color: "black",
                    position: "absolute",
                    width: "100px",
                    top: "25px",
                  }}
                >
                  <Typography
                    fontSize={isMobile ? "12px" : "14px"}
                    variant="body2"
                    fontWeight={index === activeStep ? 700 : 400}
                  >
                    {label}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>

      {activeStep === 0 && (
        <Grid container p={1}>
          <Grid item xs={12} md={6} display={"flex"} flexDirection={"column"} gap={1} p={1}>
            <Controller
              control={control}
              name="firstName"
              render={({ field }) => (
                <ControlledInput
                  label={"First Name *"}
                  error={errors.firstName?.message}
                  id="firstName"
                  placeholder="Enter your first name"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} display={"flex"} flexDirection={"column"} gap={1} p={1}>
            <Controller
              control={control}
              name="lastName"
              render={({ field }) => (
                <ControlledInput
                  label={"Last Name *"}
                  error={errors.lastName?.message}
                  id="lastName"
                  placeholder="Enter your last name"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} display={"flex"} flexDirection={"column"} gap={1} p={1}>
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <ControlledInput
                  error={errors.email?.message}
                  id="email"
                  label="Email *"
                  placeholder="Enter your email"
                  type="email"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} display={"flex"} flexDirection={"column"} gap={1} p={1}>
            <Controller
              control={control}
              name="phoneNumber"
              render={({ field }) => (
                <MuiPhone
                  label={"Phone Number *"}
                  error={errors.phoneNumber?.message}
                  id="phoneNumber"
                  placeholder="Enter your phone number"
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>
      )}
      {activeStep === 1 && (
        <Grid container p={1}>
          {searchItems.map((item) => (
            <Grid
              key={item}
              item
              xs={12}
              md={6}
              display={"flex"}
              flexDirection={"column"}
              gap={1}
              p={1}
            >
              <ControlledInput
                disabled={item === "priceOnWebsite" || item === "mileage" || selected}
                label={
                  <Typography sx={{ textTransform: "capitalize" }}>
                    {item.replace(/([a-z])([A-Z])/g, "$1 $2")}
                  </Typography>
                }
                endAdornment={
                  item !== "priceOnWebsite" && item !== "mileage" && <InputSearchIcon />
                }
                onChange={(e: any) =>
                  setFilters((filters) => ({ ...filters, [item]: e.target.value }))
                }
                value={filters[item as keyof typeof filters]}
                autoFocus={false}
              />
            </Grid>
          ))}
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              height: "fit-content",
              maxHeight: isSmall ? "300px" : "200px",
              overflowY: "auto",
              marginTop: filteredSearchOptions.length > 0 ? "32px" : "0",
            }}
          >
            {filteredSearchOptions.length > 0 &&
              filteredSearchOptions.map((item, i) => (
                <Box
                  sx={{
                    border: `1px solid ${selected?.vin === item.vin ? "#07A4FC" : "#E5E5E5"}`,
                    borderRadius: "4px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "10px",
                    cursor: "pointer",
                    color: "#555555",
                    fontWeight: "400",
                    backgroundColor:
                      selected?.vin === item.vin ? "#EAF7FE" : i % 2 === 0 ? "#F2F2F2" : "",
                    ":hover": {
                      backgroundColor: selected?.vin === item.vin ? "#EAF7FE" : "#F7F9FC",
                    },
                  }}
                  onClick={() => {
                    setIsSkipped(false);
                    setSelected(item);
                  }}
                  key={i}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: isSmall ? "column" : "row",
                      justifyContent: "space-between",
                      width: "90%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: isSmall ? "space-between" : "flex-start",
                        width: isSmall ? "100%" : "fit-content",
                        marginBottom: isSmall ? "20px" : "",
                      }}
                    >
                      <Box sx={{ width: isSmall ? "8%" : "80px" }}>{item.year}</Box>
                      <Box
                        sx={{
                          width: isSmall ? "30%" : "80px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          paddingRight: "10px",
                        }}
                      >
                        {item.make}
                      </Box>
                      <Box
                        sx={{
                          width: isSmall ? "30%" : "120px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          paddingRight: "10px",
                        }}
                      >
                        {item.model}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        paddingRight: "10px",
                        width: isSmall ? "45%" : "200px",
                      }}
                    >
                      {item.vin}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: "30px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    {selected && selected.vin === item.vin && (
                      <CloseIcon
                        onClick={(e: any) => {
                          e.stopPropagation();
                          setSelected(undefined);
                          setFilters((filters) => ({
                            ...filters,
                            year: "",
                            make: "",
                            model: "",
                            priceOnWebsite: "",
                            VIN: "",
                            mileage: "",
                          }));
                        }}
                      />
                    )}
                  </Box>
                </Box>
              ))}
          </Grid>
        </Grid>
      )}
      {activeStep === 2 && !calendarOpen && (
        <Box width={"100%"} p={1}>
          {!isSkipped && renderTestDrive}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <Textarea
                  rows={4}
                  label="Tell A.I. about this lead*"
                  // error={!!errors.description}
                  id="description"
                  placeholder="Enter your description"
                  type="text"
                  {...field}
                />
              )}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Controller
              control={control}
              name="manualLeadFirstMessage"
              render={({ field }) => (
                <Textarea
                  label="First message (optional)"
                  rows={4}
                  error={!!errors.manualLeadFirstMessage}
                  id="manualLeadFirstMessage"
                  placeholder="Enter your first message"
                  type="text"
                  {...field}
                />
              )}
            />
          </Box>
          {false && (
            <>
              {timestamp !== "" ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    justifyContent: "space-between",
                    marginTop: "20px",
                  }}
                >
                  <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                    Select date and time for appointment book:
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "80px",
                      justifyContent: "space-between",
                      borderRadius: "15px",
                      border: "1px solid #07A4FC",
                      backgroundColor: "#CEEBF840",
                      padding: "10px",
                    }}
                  >
                    <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
                      <CalendarFilledIcon />
                      <Box>
                        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                          Appointment Booked
                        </Typography>
                        <Typography variant="caption">{formatDateFrom(timestamp)}</Typography>
                      </Box>
                    </Box>
                    <Box display="flex" flexDirection="row" gap="5px" alignItems="center">
                      <Button
                        sx={{ width: "150px" }}
                        variant="outlined"
                        startIcon={<CalendarOutlinedIcon />}
                        color="primary"
                        onClick={handleOpenMenu}
                      >
                        Change Date
                      </Button>
                      <IconButton
                        icon={<RemoveIcon />}
                        onClick={() => {
                          setTimestamp("");
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "5px",
                    marginTop: "10px",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                    Select date and time for appointment book:
                  </Typography>
                  <Button
                    sx={{ width: "170px" }}
                    variant="outlined"
                    startIcon={<CalendarOutlinedIcon />}
                    color="primary"
                    onClick={handleOpenMenu}
                    disabled
                  >
                    Open Calendar
                  </Button>
                </Box>
              )}
            </>
          )}
        </Box>
      )}
      {activeStep === 2 && calendarOpen && (
        <Scheduler
          timestampHandler={(timestamp: string) => setTimestamp(timestamp)}
          onClose={() => setCalendarOpen(false)}
          timestamp={timestamp}
        />
      )}
    </Box>
  );

  const renderActions = !calendarOpen && (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          width: "100%",
          gap: "10px",
        }}
      >
        <Button variant="outlined" color="primary" disabled={activeStep === 0} onClick={handleBack}>
          Back
        </Button>
        {activeStep === 1 && (
          <Button variant="outlined" color="primary" onClick={handleSkipCarInfo}>
            Skip
          </Button>
        )}
        <Button disabled={buttonDisabled} onClick={handleNext} variant="contained">
          {activeStep === steps.length - 1 ? (calendarOpen ? "save" : "Create") : "Next"}
        </Button>
      </Box>
    </React.Fragment>
  );

  return (
    <>
      <Snackbar
        handleClose={() => setMessage({ text: "", open: false, type: "" })}
        message={message.text}
        open={message.open}
        type={message.type}
      />
      <Dialog
        maxWidth={"sm"}
        onSubmit={handleSubmit(addNewLead)}
        open={open}
        handleClose={handleCloseDialog}
        renderTitle={renderTitle}
        renderContent={renderContent}
        renderActions={renderActions}
      />
    </>
  );
}
