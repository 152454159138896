import React from "react";
import { Typography } from "@mui/material";

export default function InputError({ message }: { message?: string }) {
  return (
    <Typography sx={{ color: "#E84933", fontSize: "12px", marginTop: "5px" }}>
      {message ? message : "This field is required!"}
    </Typography>
  );
}
