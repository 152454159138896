import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
// import IconButton from "@mui/material/IconButton";
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import PersonIcon from "@mui/icons-material/Person";
import {
  activeConIdSet,
  dealerNameSet,
  getActiveConId,
  getDealerName,
  getOpenCreateLead,
  openCreateLeadToggled,
} from "../redux/userSlice";
import ResetPassword from "../sections/auth/reset-password";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import MenuIcon from "@mui/icons-material/Menu";
import { getMode, modeSet } from "../redux/layoutSlice";

import { authSet, getUid, logout } from "../redux/authSlice";
import { getData } from "../utils/firebase";
import CreateLead from "../sections/dashboard/create-lead";

import Fab from "../components/fab/fab";
import NavMenu from "../components/nav-menu";
import Feedback from "../sections/dashboard/feedback";
import Voice from "../sections/dashboard/voice";
import { child, get } from "firebase/database";
import { dbRef, firebaseApp } from "../firebase/app";
import Dialog from "../components/dialog/dialog";
import { getAuth, signOut } from "firebase/auth";
import { useResponsive } from "../hooks/use-media-query";
import OutlineAnimationButton from "../components/button/outline-animation-button";
import Messenger from "../assets/icon/messenger";
import AppointmentIcon from "../assets/icon/appointment-icon";
import ReportIcon from "../assets/icon/report";
import Notify from "../sections/notify/notify";
import AnimationButton from "../components/button/animation-button";
import { Button } from "../components";
import InventoryIcon from "../assets/icon/inventory";

function ContactUs({ open }: any) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    const auth = getAuth(firebaseApp);
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        dispatch(authSet(undefined));
        dispatch(logout());
        navigate("login");
      })
      .catch((error) => console.log(error));
  };

  const renderTitle = "Contact support";

  const renderContent = (
    <Typography align="center">
      Your account has been suspended, please contact support.
      <br /> (888) 288-8856.
    </Typography>
  );

  return (
    <Dialog
      open={open}
      handleClose={handleLogout}
      fullWidth
      renderContent={renderContent}
      renderTitle={renderTitle}
    />
  );
}

const Navbar = () => {
  // State
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openResetPassword, setOpenResetPassword] = React.useState(false);
  const [openFeedBackDialog, setOpenFeedBackDialog] = React.useState(false);
  const [openVoiceDialog, setOpenVoiceDialog] = React.useState(false);
  const openCreateLead = useSelector(getOpenCreateLead);
  const [isActive, setIsActive] = React.useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mode = useSelector(getMode);
  const uid = useSelector(getUid);
  const dealerName = useSelector(getDealerName);
  const { isCustomLayout, isMobile } = useResponsive();
  const activeConId = useSelector(getActiveConId);

  React.useEffect(() => {
    const handleGetItem = () => {
      getData(`/dealers/${uid}/info/dealerName`)
        .then((r) => dispatch(dealerNameSet(r)))
        .catch((r) => console.log(r));
    };

    handleGetItem();
  }, [dispatch, uid]);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleResetPassword = () => {
    setAnchorEl(null);
    setOpenResetPassword(!openResetPassword);
  };

  // const toggleDrawer = () => {
  //   setOpen();
  // };

  const handleFeedback = () => {
    setOpenFeedBackDialog(!openFeedBackDialog);
  };

  const handleVoice = () => {
    setOpenVoiceDialog(!openVoiceDialog);
  };

  const getModeStatus = React.useCallback(() => {
    getData(`dealers/${uid}/info/dashboardSettings/theme`).then((r) => dispatch(modeSet(r)));
  }, [dispatch, uid]);

  React.useEffect(() => {
    getModeStatus();
  }, [getModeStatus]);

  React.useEffect(() => {
    get(child(dbRef, `/dealers/${uid}/info/isActive`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          setIsActive(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [uid]);

  const { pathname } = useLocation();
  const appointment = pathname === "/appointment";
  const report = pathname === "/report";
  const setting = pathname.includes("/setting");
  const cars = pathname === "/cars";
  const trainingRequest = pathname === "/training-request";
  const inbox = !cars && !setting && !report && !appointment && !trainingRequest;

  const renderRoutes = (
    <Box width={"100%"} margin={"auto"} borderRadius={3} gap={1} display={"flex"} p={1}>
      <Button
        variant="text"
        sx={{
          display: "flex",
          bgcolor: (theme: any) => inbox && theme.palette.primary[100],
          color: (theme: any) => (inbox ? theme.palette.primary.main : theme.palette.text.primary),
          px: 2,
          fontWeight: "bold",
          fontSize: "1rem",
          borderRadius: 2,
        }}
        startIcon={<Messenger />}
        onClick={() => navigate("/")}
      >
        Inbox
      </Button>
      <Button
        variant="text"
        sx={{
          display: "flex",
          bgcolor: (theme: any) => appointment && theme.palette.primary[100],
          color: (theme: any) =>
            appointment ? theme.palette.primary.main : theme.palette.text.primary,
          px: 2,
          fontWeight: "bold",
          fontSize: "1rem",
          borderRadius: 2,
        }}
        startIcon={<AppointmentIcon />}
        onClick={() => navigate("/appointment")}
      >
        Appointment
      </Button>
      <Button
        variant="text"
        sx={{
          display: "flex",
          bgcolor: (theme: any) => report && theme.palette.primary[100],
          color: (theme: any) => (report ? theme.palette.primary.main : theme.palette.text.primary),
          px: 2,
          fontWeight: "bold",
          fontSize: "1rem",
          borderRadius: 2,
        }}
        startIcon={<ReportIcon />}
        onClick={() => navigate("/report")}
      >
        Report
      </Button>

      <AnimationButton
        startIcon={<InventoryIcon />}
        onClick={() => navigate("/cars")}
        title="Cartelligent"
      />
    </Box>
  );

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          display: isMobile && activeConId ? "none" : "block",
          top: 0,
          left: 0,
          right: 0,
          px: { xs: 0, sm: 4 },
          boxShadow: "none",
          bgcolor: (theme) => theme.palette.background.paper,
          backgroundImage: "none",
          height: 70,
          borderRadius: 4,
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            display: "flex",
            justifyContent: isCustomLayout ? "center" : "space-evenly",
            height: "100%",
          }}
        >
          {/* {!isCustomLayout && (
            <IconButton
              edge="start"
              color="primary"
              aria-label="open drawer"
              sx={{
                marginLeft: { lg: -2, xs: 0 },
                marginRight: { lg: 1, xs: 0 },
                borderRadius: 1,
                bgcolor: (theme) => theme.palette.secondary.main,
              }}
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
          )} */}

          <Link onClick={() => dispatch(activeConIdSet(undefined))} to="/">
            <img
              width={77}
              src={`/images/${mode === "dark" ? "DriveeLOGOLightVersion.webp" : "drivee-logo.svg"}`}
              alt="logo Drivee"
              style={{ cursor: "pointer", display: "flex" }}
              loading="lazy"
            />
          </Link>
          {!isCustomLayout && renderRoutes}

          <Box flex={1} />
          <Box
            gap={1}
            sx={{
              flexGrow: 0,
              display: "flex",
              alignItems: "center",
            }}
          >
            {!isCustomLayout && (
              <OutlineAnimationButton title="A.I. Voice coming soon" onClick={handleVoice} />
            )}
            <Box
              bgcolor={isCustomLayout ? "" : mode === "dark" ? "#313645" : "#F8F8F8"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={1}
              p={0.5}
              borderRadius={"40px"}
              padding="5px 10px"
            >
              {!isCustomLayout && dealerName !== "" && (
                <Box display={"flex"}>
                  <Box width={"100%"} display={"flex"} alignItems={"end"} flexDirection={"column"}>
                    <Typography
                      noWrap
                      variant="body2"
                      color={(theme) => theme.palette.text.primary}
                      fontWeight={700}
                    >
                      {dealerName}
                    </Typography>
                  </Box>
                </Box>
              )}
              {!isCustomLayout && <Fab onClick={handleOpenUserMenu} icon={<PersonIcon />} />}
            </Box>
          </Box>
        </Toolbar>
        <NavMenu anchorEl={anchorEl} handleClose={handleCloseMenu} />
        <ResetPassword open={openResetPassword} handleClose={handleResetPassword} />
        <CreateLead open={openCreateLead} handleClose={() => dispatch(openCreateLeadToggled())} />
        <Feedback open={openFeedBackDialog} handleClose={handleFeedback} />
        <Voice open={openVoiceDialog} handleClose={handleVoice} />
        <ContactUs open={!isActive} />
        <Notify />
      </AppBar>
    </>
  );
};

export default Navbar;
