import { Alert, Box } from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "../../components";
import ControlledBox from "../../components/box/controlled-box";
import AddNotification from "./add-notification";

export default function Notifications({ state, setState, onMorePerson, removeNotification }: any) {
  const handleChange =
    (index: number) =>
    ({ currentTarget: input }: any) => {
      const value = input.type === "checkbox" ? input.checked : input.value; // Handle checkbox

      // Update the specific item in the state
      setState((prevState: any) => {
        const updatedItems = [...prevState]; // Create a copy of the current state
        updatedItems[index] = {
          ...updatedItems[index], // Spread the existing item
          [input.name]: value, // Update the specific field
        };
        return updatedItems; // Return the new state
      });
    };

  return (
    <Box display={"flex"} flexDirection={"column"}>
      <ControlledBox
        title="Notification"
        description="Please add information of the people who you would like to receive notifications through
          text"
      >
        {state?.length > 0 &&
          state
            ?.filter((item: any) => !item.willBeRemoved)
            .map((s: any, index: number) => (
              <AddNotification
                key={index}
                index={index}
                state={state}
                setState={setState}
                handleChange={() => handleChange(index)}
              />
            ))}
        {state?.filter((item: any) => !item.willBeRemoved).length < 2 ? (
          <Box display={"flex"}>
            <Button variant="outlined" startIcon={<AddIcon />} onClick={onMorePerson}>
              One More Person
            </Button>
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" gap="20px">
            <Box display={"flex"}>
              <Button variant="outlined" onClick={removeNotification}>
                Ignore
              </Button>
            </Box>
            <Alert
              variant="filled"
              severity="info"
              sx={{
                bgcolor: "#CDEDFE",
                color: "#027EC3",
                borderRadius: "8px",
              }}
            >
              To add one more notification number please
              <b style={{ color: "#027EC3" }}> contact support (888) 288-8856.</b>
            </Alert>
          </Box>
        )}
      </ControlledBox>
    </Box>
  );
}
