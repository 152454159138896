import React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { InputLabel } from "@mui/material";

export default function Selector({ label, data, width, ...rest }: any) {
  return (
    <FormControl size="small">
      {label && <InputLabel id="demo-simple-select-label">{label}</InputLabel>}
      <Select
        labelId="demo-simple-select-label"
        displayEmpty
        sx={{ width: width ? width : "100%", height: "40px" }}
        {...rest}
      >
        {data?.map((i: any, index: number) => (
          <MenuItem key={index} value={i}>
            {i}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
