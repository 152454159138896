import { Fab as MuiFab } from "@mui/material";
import React from "react";

export default function Fab({ icon, ...props }: any) {
  return (
    <MuiFab sx={{ boxShadow: "none" }} {...props} size="small">
      {icon}
    </MuiFab>
  );
}
