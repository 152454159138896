import { Alert, Box, Collapse } from "@mui/material";
import * as React from "react";
import IconButton from "../icon-button/icon-button";
import CloseIcon from "@mui/icons-material/Close";

const CustomSnackbar = ({ open, handleClose, message, type }: any) => {
  // Auto-close alert after a specified duration
  React.useEffect(() => {
    if (!open) return;
    const timer = setTimeout(() => {
      handleClose();
    }, 3000); // Auto-close after 3 seconds

    return () => {
      clearTimeout(timer); // Cleanup the timer on unmount
    };
  }, [handleClose, open]);

  return (
    <Box sx={{ width: "100%", display: open ? "block" : "none" }}>
      <Collapse in={open}>
        <Alert
          severity={type ? type : "success"}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                handleClose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {message}
        </Alert>
      </Collapse>
    </Box>
  );
};

export default CustomSnackbar;
