import CloseIcon from "@mui/icons-material/Close";
import { Dialog as MuiDialog, Stack, Typography } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import * as React from "react";

interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  showCloseButton?: boolean;
  handleClose?: any;
  subTitle?: any;
  smallContent?: boolean;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, showCloseButton = true, handleClose, subTitle, smallContent, ...other } = props;

  return (
    <Stack
      sx={{
        alignItems: "center",
        display: "flex",
        fontSize: "28px !important",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: smallContent ? "0" : "32px",
      }}
      {...other}
    >
      <Typography variant="h6" fontSize={"28px"} fontWeight={700}>
        {children}
        {subTitle && <Typography>{subTitle}</Typography>}
      </Typography>
      {showCloseButton && (
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      )}
    </Stack>
  );
}

export interface DialogProps {
  open: boolean;
  handleClose: () => void;
  renderTitle?: React.ReactNode;
  renderContent?: React.ReactNode;
  renderActions?: React.ReactNode;
  onSubmit?: any;
  fullWidth?: boolean;
  maxWidth?: "xs|sm|md|lg|xl" | any;
  showCloseButton?: boolean;
  maxHeight?: any;
  display?: any;
  filter?: any;
  noPadding?: boolean;
  bgColor?: any;
  subTitle?: any;
  smallContent?: boolean;
  withoutActionSpace?: boolean;
}

export default function Dialog(props: DialogProps) {
  const {
    open,
    handleClose,
    renderTitle,
    renderContent,
    renderActions,
    onSubmit,
    fullWidth = false,
    maxWidth = "sm",
    showCloseButton,
    noPadding,
    bgColor,
    subTitle,
    smallContent,
    withoutActionSpace,
  } = props;

  const BootstrapDialog = React.useMemo(() => {
    return styled(MuiDialog)(({ theme }) => ({
      "& .MuiDialog-paper": {
        padding: noPadding ? 0 : "24px",
        borderRadius: 20,
      },
      "& .MuiDialogActions-root": {
        padding: noPadding ? 0 : theme.spacing(2, 1),
      },
      "& .MuiDialogContent-root": {
        minWidth: "100%",
        minHeight: 72,
        padding: 0,
        display: "flex",
        alignItems: "center",
      },
    }));
  }, [noPadding]);

  return (
    <BootstrapDialog
      aria-labelledby="customized-dialog-title"
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      onClose={handleClose}
      open={open}
      sx={{
        backdropFilter: "blur(7.5px)",
        fill: "rgba(64, 64, 64, 0.35)",
        padding: "0",
        maxWidth: "100%",
        // mx: "auto",
        // display: "block",
        display: "flex",
        flexDirection: "column",
        gap: smallContent ? "0" : "32px",
        "& .MuiDialog-paper": {
          backgroundColor: bgColor ? bgColor : (theme: any) => theme.bg.paper,
        },
        "& .MuiBackdrop-root-MuiDialog-backdrop": {
          backgroundColor: "rgba(0, 0, 0, 0.40)",
          backdropFilter: "blur(3.5px)",
        },
      }}
    >
      {renderTitle && (
        <BootstrapDialogTitle
          handleClose={handleClose}
          id="customized-dialog-title"
          showCloseButton={showCloseButton}
          subTitle={subTitle}
          smallContent={smallContent}
        >
          {renderTitle}
        </BootstrapDialogTitle>
      )}
      <form
        onSubmit={onSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: smallContent || withoutActionSpace ? "0" : "32px",
        }}
      >
        <DialogContent
          sx={{
            height: "fit-content !important",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {renderContent}
        </DialogContent>
        {renderActions && (
          <DialogActions sx={{ padding: "0 !important" }}>{renderActions}</DialogActions>
        )}
      </form>
    </BootstrapDialog>
  );
}
