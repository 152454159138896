import React from "react";
import { Box, SelectChangeEvent } from "@mui/material";

import { useState } from "react";

import { generateClock } from "../../utils/utils.service";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Selector from "../../components/select/select";
import dayjs from "dayjs";
import Button from "../button/button";
import EditableSelect from "./editable-select";

export default function Scheduler({ timestampHandler, onClose, timestamp }: any) {
  const [date, setDate] = useState(timestamp ? new Date(timestamp) : new Date());
  const [morning, setMorning] = useState("AM");
  const [hour, setHour] = useState("8:00");

  const generateTimestamp = () => {
    const [hourPart, minutePart] = hour.split(":").map((num) => parseInt(num, 10));
    const newDate = new Date(date);
    let newHour = hourPart;
    if (morning === "PM" && hourPart !== 12) {
      newHour += 12;
    } else if (morning === "AM" && hourPart === 12) {
      newHour = 0;
    }
    newDate.setHours(newHour, minutePart, 0, 0);
    timestampHandler(newDate.getTime());
    onClose();
  };

  const renderContent = (
    <Box
      sx={{
        backgroundColor: (theme: any) => theme.bg[100],
        marginX: "15px",
        borderRadius: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingY: "20px",
        paddingX: "10px",
        gap: "20px",
      }}
    >
      <Box
        sx={{
          backgroundColor: (theme: any) => theme.bg.paper,
          width: "fit-content",
          borderRadius: "16px",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            showDaysOutsideCurrentMonth={true}
            sx={{
              "& .MuiButtonBase-root  .MuiPickersDay-root .Mui-selected  ": {
                backgroundColor: "#07A4FC",
                color: "white",
                borderRadius: "5px",
              },
              "& .MuiButtonBase-root": {
                borderRadius: "5px",
                color: "#7B7B7B",
              },
              "& .MuiPickersDay-dayOutsideMonth": {
                color: "#d4d4d4",
              },
            }}
            value={dayjs(date)}
            onChange={(d) => {
              setDate(new Date(d));
            }}
          />
        </LocalizationProvider>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: "20px" }}>
        <EditableSelect hour={hour} setHour={setHour} options={generateClock()} />

        <Selector
          data={["AM", "PM"]}
          width={85}
          sx={{
            borderRadius: "20px",
            minWidth: "100px",
            backgroundColor: (theme: any) => theme.bg.paper,
          }}
          value={morning}
          onChange={(e: SelectChangeEvent) => setMorning(e.target.value)}
        />
      </Box>
    </Box>
  );

  const renderActions = (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          pt: 2,
          width: "100%",
          gap: "10px",
        }}
      >
        <Button variant="outlined" color="primary" onClick={onClose}>
          Cancel
        </Button>

        <Button onClick={generateTimestamp} variant="contained">
          Save
        </Button>
      </Box>
    </React.Fragment>
  );
  return (
    <Box>
      {renderContent}
      {renderActions}
    </Box>
  );
}
