import { SvgIcon } from "@mui/material";
import React from "react";

export default function RemoveIcon({ ...props }) {
  return (
    <SvgIcon
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.5" y="0.5" width="20" height="20" rx="10" stroke="#7B7B7B" />
        <path
          d="M15.1663 6.7735L14.2263 5.8335L10.4997 9.56016L6.77301 5.8335L5.83301 6.7735L9.55967 10.5002L5.83301 14.2268L6.77301 15.1668L10.4997 11.4402L14.2263 15.1668L15.1663 14.2268L11.4397 10.5002L15.1663 6.7735Z"
          fill="#7B7B7B"
        />
      </svg>
    </SvgIcon>
  );
}
