import React, { useEffect, useState } from "react";
import { getData, updateData } from "../../utils/firebase";
import { useSelector } from "react-redux";
import { getUid } from "../../redux/authSlice";
import { Box, Typography } from "@mui/material";
import Dialog from "../../components/dialog/dialog";
import { Button } from "../../components";
import constants from "../../config/constant";

export default function Notify() {
  const uid = useSelector(getUid);
  const [notification, setNotification]: any = useState();

  useEffect(() => {
    const getNotification = () => {
      getData(`dealers/${uid}/info/notification`).then((r: any) => {
        if (!r) return;
        Object.entries(r).find(([key, item]: any) => {
          if (item.role === "system") {
            if (!item?.seenTimestamp) return;
            const currentTimestamp = Date.now();
            const fiveHoursInMilliseconds = constants.TIMESTAMP; // 5 hours in milliseconds
            const subtractCurrentTimestamp = currentTimestamp - fiveHoursInMilliseconds;
            if (subtractCurrentTimestamp < item?.seenTimestamp) return;
            const updatedNotification = { ...item, id: key }; // Create the updated notification
            setNotification(updatedNotification);
          }
        });
      });
    };
    getNotification();
  }, [uid]);

  const handleClose = () => {
    setNotification(null);
  };
  const handleData = () => {
    const currentTimestamp = Date.now();
    const path = `dealers/${uid}/info/notification/${notification?.id}`;
    const data = { seenTimestamp: currentTimestamp, read: true };
    updateData(path, data).then(() => handleClose());
  };
  const convertTime = (timestamp: string) => {
    const futureTimestamp = new Date(timestamp).getTime();
    // Current timestamp
    const currentTimestamp = Date.now(); // or new Date().getTime()
    // Calculate the difference in milliseconds
    const differenceInMilliseconds = futureTimestamp - currentTimestamp;
    // Convert the difference to days
    return Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
  };
  const convertText = (text: string) => {
    const differenceInDays = convertTime(notification?.timestamp);
    return text?.replace("{}", String(differenceInDays)); // Replace '{}' with 'differenceInDays'
  };
  const renderTitle = "Contact Support";

  const renderContent = <Typography align="left">{convertText(notification?.text)}</Typography>;
  const renderActions = (
    <Box display={"flex"} justifyContent={"center"} width={"100%"} gap={1}>
      <Button onClick={handleClose} variant="outlined">
        Cancel
      </Button>
      <Button onClick={handleData} variant="contained">
        Ok
      </Button>
    </Box>
  );
  return (
    <Dialog
      open={Boolean(notification)}
      handleClose={handleClose}
      fullWidth
      renderContent={renderContent}
      renderTitle={renderTitle}
      renderActions={renderActions}
    />
  );
}
