import { SvgIcon } from "@mui/material";
import React from "react";

export default function CalendarFilledIcon({ ...props }) {
  return (
    <SvgIcon
      width="60"
      height="51"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <svg
        width="60"
        height="51"
        viewBox="0 0 40 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="0.5" width="40" height="40" rx="12" fill="#07A4FC" fillOpacity="0.1" />
        <g clipPath="url(#clip0_3302_70196)">
          <path
            d="M30.9647 19.0234V26.6425C30.9647 28.2138 29.5182 29.4996 27.7505 29.4996H12.7504C10.9827 29.4996 9.53613 28.2138 9.53613 26.6425V19.0234H30.9647Z"
            fill="#07A4FC"
          />
          <path
            d="M30.9647 15.2143V17.1191H9.53613V15.2143C9.53613 13.643 10.9827 12.3571 12.7504 12.3571H14.8933V10.4524C14.8933 9.92875 15.3754 9.5 15.9647 9.5C16.5541 9.5 17.0361 9.92875 17.0361 10.4524V12.3571H23.4647V10.4524C23.4647 9.92875 23.9468 9.5 24.5362 9.5C25.1255 9.5 25.6076 9.92875 25.6076 10.4524V12.3571H27.7505C29.5182 12.3571 30.9647 13.6429 30.9647 15.2143Z"
            fill="#07A4FC"
          />
        </g>
        <defs>
          <clipPath id="clip0_3302_70196">
            <rect width="22.5" height="20" fill="white" transform="translate(9 9.5)" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}
