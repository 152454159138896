import { SvgIcon } from "@mui/material";
import React from "react";

export default function InputSearchIcon({ ...props }) {
  return (
    <SvgIcon
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_4817_39601)">
          <path
            d="M19.1695 16.777C20.7661 14.6434 21.4951 11.9842 21.2098 9.33473C20.9246 6.68525 19.6463 4.24219 17.6322 2.49732C15.6181 0.752445 13.0178 -0.164675 10.3547 -0.0694322C7.69163 0.0258105 5.16348 1.12634 3.27919 3.01064C1.3949 4.89493 0.294365 7.42307 0.199122 10.0862C0.10388 12.7492 1.021 15.3495 2.76587 17.3636C4.51074 19.3777 6.95381 20.656 9.60329 20.9413C12.2528 21.2265 14.9119 20.4975 17.0455 18.901L22.1455 24.001L24.2695 21.881L19.1695 16.777ZM10.7695 18.001C9.28617 18.001 7.83612 17.5611 6.60275 16.737C5.36938 15.9129 4.40809 14.7415 3.84043 13.3711C3.27277 12.0007 3.12425 10.4927 3.41364 9.0378C3.70303 7.58294 4.41733 6.24657 5.46623 5.19767C6.51512 4.14878 7.85149 3.43447 9.30635 3.14508C10.7612 2.85569 12.2692 3.00422 13.6397 3.57188C15.0101 4.13953 16.1814 5.10083 17.0056 6.3342C17.8297 7.56756 18.2695 9.01761 18.2695 10.501C18.2677 12.4895 17.4769 14.3961 16.0708 15.8022C14.6647 17.2083 12.7581 17.9991 10.7695 18.001Z"
            fill="#E6E6E6"
          />
        </g>
        <defs>
          <clipPath id="clip0_4817_39601">
            <rect width="24" height="24" fill="white" transform="translate(0.269531)" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}
