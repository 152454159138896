import { Box } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import ControlledInput from "../../components/input/controlled-input";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import ControlledBox from "../../components/box/controlled-box";
import MuiPhone from "../../components/input/mui-phone-input";
import { yupResolver } from "@hookform/resolvers/yup";
import { EMAIL_REGEX } from "../../utils/utils.service";

const schema = yup.object().shape({
  name: yup.string().required("Please enter dealership name"),
  phoneNumber: yup.string().required("Please enter your phone number").min(12),
  email: yup
    .string()
    .required("Please enter your email")
    .email("Please enter a valid email")
    .matches(EMAIL_REGEX, "Please enter a valid email"),
  address: yup.string().required("Please enter your address"),
});

interface FormInputs {
  name: string;
  phoneNumber: string;
  email: string;
  address: string;
}

export default function DealershipInformation({ state, setState }: any) {
  const {
    control,
    formState: { errors },
    watch,
  } = useForm<FormInputs>({
    defaultValues: useMemo(() => {
      return {
        name: state?.name,
        phoneNumber: state?.phone_number,
        email: state?.email,
        address: state?.dealerAddress,
      };
    }, [state?.dealerAddress, state?.email, state?.name, state?.phone_number]),
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const subscription = watch(({ name, phoneNumber: phone_number, email, address }: any) => {
      setState({
        ...state,
        name,
        phone_number,
        email,
        dealerAddress: address,
      });
    });
    return () => subscription.unsubscribe();
  }, [setState, state, watch]);

  return (
    <Box gap={1} display={"flex"} flexDirection={"column"}>
      <Box display={"flex"}>
        <ControlledBox title="Dealership Information">
          <Box display={"flex"} gap={2}>
            <Box width="100%">
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <ControlledInput
                    error={errors.name && errors.name.message}
                    id="fullName"
                    label="Dealership Name"
                    placeholder="Enter dealership name"
                    type="text"
                    {...field}
                  />
                )}
              />
            </Box>
            {/* <Box width="100%">
              <ControlledInput
                placeholder="Enter Your Name"
                value={state?.name}
                label="Dealership Name"
                name="name"
                onChange={onChange}
              />
              {!state?.name && (
                <Typography sx={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
                  Name is required
                </Typography>
              )}
            </Box> */}
            <Box width="100%">
              <Controller
                control={control}
                name="phoneNumber"
                render={({ field }) => (
                  <MuiPhone
                    // value={state?.phone_number}
                    label={"Phone Number"}
                    id="phoneNumber"
                    placeholder="Enter your phone number"
                    // error={errors.phoneNumber?.message}
                    // onChange={(e: any) => {
                    //   setState({ ...state, phone_number: e });
                    //   if (validateMobileNumber(e)) onValidate(true);
                    //   else onValidate(false);
                    // }}
                    error={errors.phoneNumber && errors.phoneNumber.message}
                    {...field}
                  />
                )}
              />
              {/* {!validateMobileNumber(state?.phone_number) && (
                <InputError message="please enter your phone number" />
              )} */}
            </Box>
            {/* <FormControl fullWidth={true}>
              <MuiPhone
                value={state?.phone_number}
                label={"Phone Number"}
                id="phone_number"
                placeholder="Enter your phone number"
                onChange={(e: any) => {
                  setState({ ...state, phone_number: e });
                  if (validateMobileNumber(e)) onValidate(true);
                  else onValidate(false);
                }}
                error={validateMobileNumber(state?.phone_number) ? "" : "error"}
              />
            </FormControl> */}
            <Box width="100%">
              <Controller
                control={control}
                name="email"
                render={({ field }) => (
                  <ControlledInput
                    error={errors.email && errors.email.message}
                    id="email"
                    label="Dealership Email"
                    placeholder="Enter your email"
                    type="text"
                    {...field}
                  />
                )}
              />
            </Box>
            {/* <ControlledInput
              placeholder="Enter Your Email"
              label="Dealership email"
              value={state?.email}
              onChange={onChange}
              name="email"
            /> */}
          </Box>
          <Box width="100%" marginTop="10px">
            <Controller
              control={control}
              name="address"
              render={({ field }) => (
                <ControlledInput
                  error={errors.address && errors.address.message}
                  id="address"
                  label="Dealership Address"
                  placeholder="Enter your address"
                  type="text"
                  {...field}
                />
              )}
            />
          </Box>
        </ControlledBox>
      </Box>
    </Box>
  );
}
