import {
  child,
  equalTo,
  get,
  onValue,
  orderByChild,
  query,
  ref,
  limitToLast,
  update,
  push,
} from "firebase/database";
import { dbRef, firebaseDb } from "../firebase/app";

export function getDataByQL(
  path: any,
  orderBy: string,
  equal: string | number | boolean | null
): any {
  const databaseRef = query(ref(firebaseDb, path), orderByChild(orderBy), equalTo(equal));
  return new Promise((resolve, reject) => {
    onValue(
      databaseRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const size = snapshot.size;
          resolve(size);
        } else reject(null);
      },
      (error) => {
        reject(error);
      },
      { onlyOnce: true }
    );
  });
}

export function getDataByQ(
  path: any,
  orderBy: string,
  equal: string | number | boolean | null
): any {
  const databaseRef = query(ref(firebaseDb, path), orderByChild(orderBy), equalTo(equal));
  return new Promise((resolve, reject) => {
    onValue(
      databaseRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const keys: any = [];

          snapshot.forEach(function (childSnapshot) {
            const childKey = childSnapshot.key;
            keys.push(childKey);
          });

          resolve(keys);
        } else reject(null);
      },
      (error) => {
        reject(error);
      },
      { onlyOnce: true }
    );
  });
}

export function getDataBy(
  path: any,
  orderBy: string,
  equal: string | number | boolean | null
): any {
  const databaseRef = query(ref(firebaseDb, path), orderByChild(orderBy), equalTo(equal));
  return new Promise((resolve, reject) => {
    onValue(
      databaseRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const value: any = snapshot.val();

          resolve(value);
        } else resolve([]);
      },
      (error) => {
        reject(error);
      },
      { onlyOnce: true }
    );
  });
}

export function getDataByKey(path: any, equal: string | number | boolean | null): any {
  const databaseRef = query(ref(firebaseDb, path), equalTo(equal));
  return new Promise((resolve, reject) => {
    onValue(
      databaseRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const value: any = snapshot.val();

          resolve(value);
        } else resolve([]);
      },
      (error) => {
        reject(error);
      },
      { onlyOnce: true }
    );
  });
}

export function getDataL(path: any) {
  const databaseRef = query(ref(firebaseDb, path));
  return new Promise((resolve, reject) => {
    onValue(
      databaseRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const size = snapshot.size;
          resolve(size);
        } else reject(null);
      },
      (error) => {
        reject(error);
      },
      { onlyOnce: true }
    );
  });
}

export function getData(path: any) {
  return new Promise((resolve, reject) => {
    get(child(dbRef, path))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          resolve(data);
        } else resolve(undefined);
      })
      .catch((e) => {
        reject(e);
        console.log(e);
      });
  });
}

export function getDataLTL(path: any, limit: number, orderByC: string) {
  return new Promise((resolve, reject) => {
    const ref = child(dbRef, path);
    const q = query(ref, limitToLast(limit), orderByChild(orderByC));
    get(q)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          resolve(data);
        }
      })
      .catch((e) => {
        reject(e);
        console.log(e);
      });
  });
}

export function getDataOE(path: string, orderBy: string, equal: boolean) {
  return new Promise((resolve, reject) => {
    const ref = child(dbRef, path);
    const q = query(ref, orderByChild(orderBy), equalTo(equal));
    get(q)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          resolve(data);
        } else resolve([]);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export function getDataOEL(path: string, orderBy: string, equal: boolean | string) {
  return new Promise((resolve, reject) => {
    const ref = child(dbRef, path);
    const q = query(ref, orderByChild(orderBy), equalTo(equal));
    get(q)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.size;
          resolve(data);
        } else resolve(0);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export function updateData(path: string, data: object) {
  return new Promise((resolve, reject) => {
    const currentUserRef = ref(firebaseDb, path);
    update(currentUserRef, data)
      .then((r) => resolve(r))
      .catch((e) => reject(e));
  });
}

// Function to create a push ID
export const createPushId = () => {
  const pushesRef = ref(firebaseDb, "pushes"); // Reference to the "pushes" node
  const newPushRef = push(pushesRef); // Create a new push ID
  const generatedId = newPushRef.key; // Get the generated ID
  return generatedId;
};

export const handlePush = (path: string | undefined, data: object) => {
  return new Promise((resolve, reject) => {
    const currentUserRef = ref(firebaseDb, path);
    push(currentUserRef, data)
      .then((r) => resolve(r))
      .catch((e) => reject(e));
  });
};
