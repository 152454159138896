import React from "react";

import { Box, Box as MUBox, Typography } from "@mui/material";

export default function ControlledBox({ children, title, description, ...props }: any) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px", width: "100%" }}>
      {title && (
        <Box display={"block"}>
          <Typography variant="h6" sx={{ fontSize: "20px", fontWeight: "700" }}>
            {title}
          </Typography>
          {description && (
            <Typography color={"#9B9B9B"} variant="body1" sx={{ fontSize: "16px" }}>
              {description}
            </Typography>
          )}
        </Box>
      )}
      <MUBox
        bgcolor={(theme) => theme.palette.background.paper}
        py={2}
        px={2}
        marginBottom={"10px"}
        borderRadius={4}
        width={"100%"}
        {...props}
      >
        {children}
      </MUBox>
    </Box>
  );
}
